import './Carousel.css';
import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from 'styled-components'
import { color, space, fontSize } from './constants';

const FullWidthGroup = styled.div`
    left: 0;
    right: 0;
    border-radius: 24px;
    border: 1px solid ${color.gray7};
    margin: 0px 36px 36px;
    ${'' /* overflow: hidden; */}
    @media (max-width: 1024px){
        margin: 0px 36px 36px;
    }
    @media (max-width: 640px){
        margin: 0px 24px 36px;
    }
`

const ImageContainer = styled.div`
    left: 0;
    right: 0;
    height: 0px;
    padding-top: 50%;
    border-radius: 24px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center top;
    `

const DemoCarousel = props => (
<FullWidthGroup>
  <Carousel autoPlay={false} showArrows={true} showThumbs={false} showStatus={false}>
    <div>
    <ImageContainer image={require('../images/ds-1-LG.jpg')}/>
    </div>
    <div>
    <ImageContainer image={require('../images/ds-1-LG.jpg')}/>
    </div>
    <div>
    <ImageContainer image={require('../images/ds-1-LG.jpg')}/>
    </div>
    <div>
    <ImageContainer image={require('../images/ds-1-LG.jpg')}/>
    </div>
    <div>
    <ImageContainer image={require('../images/ds-1-LG.jpg')}/>
    </div>
  </Carousel>
  </FullWidthGroup>
);

export default DemoCarousel
