import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants';
import DemoCarousel from '../components/CarouselComponent';
import styled from 'styled-components'

const SectionGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    margin: 0px 36px 10px;

    @media (max-width: 1024px){
        grid-gap: 0px;
        margin: 0px 36px 0px;
    }

    @media (max-width: 640px){
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr;
        grid-gap: 0px;
        margin: 0px 24px 0px;
    }
`
const CellTitleGroup = styled.div`
    grid-column: 2;
    width: 100%;
    @media (max-width: 640px){
        grid-column: 1;
        grid-row: 2;
        width: 100%;
        margin-top: 0px;
    }
`
const Title = styled.div`
    color: ${props => props.color ? color[props.color] : color.white};
    font-size: ${fontSize[2]};
    font-weight: 200;
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }
`

const Text = styled.p`
    margin-top: ${space[4]+ "px"};
    color: ${color.gray3};
    word-wrap:normal; /* YESSSSS */
    @media (max-width: 1024px){
        font-size: 16px;
    }
`


const SecondPage = () => (
  <LayoutChild>
    <SEO title="This is the title" />
    <DemoCarousel />
    <SectionGroup>
      <CellTitleGroup>
          <Title color={color.white}>Here's the title</Title>
          <Text>Here's all the text here</Text>
          </CellTitleGroup>
      </SectionGroup>
  </LayoutChild>
)

export default SecondPage
